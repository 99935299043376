.statistic {
	background: $off-white-1;
	padding: $padding-rythm-sm;
	padding-top: 0;
	position: relative;
	margin-top: 1em;

	&--title {
		color: white;
		background: $red-9;
		padding: 7px;
		display: inline-block;
		margin: 0 auto;
		font-size: $fs-body;
		white-space: nowrap;

		position: absolute;
		top: -1em;
		left: 50%;
		transform: translateX(-50%);
	}

	&--description {
		margin-top: $padding-rythm-sm;
	}
}

@include to($bp-small) {
	.statistic-group {
		.statistic + .statistic {
			margin-top: calc(1em + #{$padding-rythm-sm * 2});
		}
	}
}

@include to($bp-medium) {
	.statistic {
		svg {
			max-width: 250px;
			margin: 0 auto;
			display: block;
		}
	}
}

@include from($bp-small) {
	.statistic-group {
		display: flex;

		@supports (display: grid) {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			grid-gap: $padding-rythm-sm;
		}
	}
}

@include from($bp-medium) {
	.statistic {
		&--title {
			font-size: $fs-h3;
		}
	}

	.statistic-group {
		grid-gap: $padding-rythm-sm * 2;
		&--title {
			font-size: $fs-h3;
		}
	}
}
