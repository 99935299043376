.l-map-block {
	width: 100%;
	position: relative;
	padding-bottom: 48.83%;

	&--point,
	&--map-asset {
		transition: opacity 150ms ease-in-out;
		opacity: 0;
		.has-finished-point-layout & {
			opacity: 1;
		}
	}

	&--map-asset {
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		position: absolute;
	}

	&--point {
		$point-root: &;
		width: 0;
		height: 0;
		position: absolute;
		z-index: 1;

		&-asset {
			position: absolute;
			bottom: 0;
			width: 22px;
			height: 30px;
			left: -11px;
			color: $off-white-1;
		}

		&-card {
			position: absolute;
			width: 291px;
			left: 100%;
			top: 0;

			transform: translateY(-100%);

			transition: opacity 150ms ease-in-out;
			opacity: 0;
			pointer-events: none;
			padding-left: $padding-rythm-sm + 11px;

			&-inner {
				background: $red-9;
				color: white;
				padding: $padding-rythm-sm;
				position: relative;

				&:before {
					content: "";
					width: 0;
					height: 0;
					border-top: 8px solid transparent;
					border-bottom: 8px solid transparent;
					border-right: 16px solid $red-9;
					position: absolute;
					right: 100%;
					bottom: 0;
				}
			}

			&-name {
				font-size: $fs-body;
				margin-bottom: 0.5em;
			}
			&-role,
			&-location {
				font-size: $fs-body-md;
			}
			&-role {
				font-style: italic;
			}
			&-image {
				position: absolute;
				top: 100%;
				left: 0;
				width: 100%;
				height: auto;
			}
		}

		&.is-active,
		&:hover {
			z-index: 10;
			cursor: pointer;
			#{$point-root}-card {
				opacity: 1;
				pointer-events: all;
			}
			#{$point-root}-asset {
				color: $red-7;
			}
		}

		&.is-past-middle {
			#{$point-root}-card {
				left: auto;
				right: 100%;
				padding-left: 0;
				padding-right: 31px;
				&-inner {
					&:before {
						right: auto;
						left: 100%;
						border-left: 16px solid $red-5;
						border-right: 0 none;
					}
				}
			}
		}
	}
}

@include to($bp-medium) {
	.l-map-block {
		transform: translateZ(0);
		&--point {
			$point-root: &;

			&:before {
				transition: opacity 150ms ease-in-out;
				content: "";
				width: 0;
				height: 0;
				border-left: 8px solid transparent;
				border-right: 8px solid transparent;
				border-bottom: 16px solid $red-5;
				position: absolute;
				top: 100%;
				left: -8px;
				opacity: 0;
			}
			&.is-active,
			&:hover {
				&:before {
					opacity: 1;
				}
			}

			&-card {
				position: fixed;
				left: 0;
				top: auto;
				transform: translateY(16px);
				padding-left: $padding-rythm-sm;
				&-inner {
					&:before {
						content: none;
					}
				}
			}
			&.is-past-middle {
				#{$point-root}-card {
					left: auto;
					right: 0;
					padding-left: 0;
					padding-right: $padding-rythm-sm;
				}
			}
		}
	}
}
