.l-site-footer {
	padding: $padding-rythm-sm * 3 $padding-rythm-sm;
	background: $grey-6;
	color: white;

	&--social {
		&-title {
			margin-bottom: $padding-rythm-sm;
		}
	}

	&--logos {
		display: flex;
		align-items: center;
		&-img {
			height: auto;
			width: 50%;
			display: block;
			padding-left: $padding-rythm-sm;
			max-width: 180px;
			&:first-child {
				padding-left: 0;
				padding-right: $padding-rythm-sm;
				border-right: 1px solid $grey-2;
			}
		}
	}

	&--copyright {
		font-size: $fs-body-sm;
	}
}

@include to(374px) {
	.l-site-footer {
		.menu-group + .menu-group {
			margin-top: $padding-rythm-sm * 2;
		}
	}
}

@include from-to(375px, $bp-medium) {
	.l-site-footer {
		&--navigation {
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-gap: $padding-rythm-sm;
			font-size: $fs-body-md;
		}
	}
}

@include to($bp-medium) {
	.l-site-footer {
		&--social,
		&--logos {
			margin-top: $padding-rythm-sm * 3;
		}

		&--copyright {
			margin-top: $padding-rythm-sm;
		}
	}
}

@include from($bp-medium) {
	.l-site-footer {
		padding: $section-padding;

		@supports (display: grid) {
			display: grid;
			grid-gap: $padding-rythm-sm;
			grid-template-columns: repeat(6, 1fr);

			&--navigation {
				grid-column: span 2;

				display: grid;
				grid-gap: $padding-rythm-lg / 2;
				grid-template-columns: repeat(2, 1fr);
			}

			&--social {
				grid-column: 6/7;
			}

			&--logos {
				grid-column: span 3;
				grid-row: 2;
				&-img {
					height: 48px;
					width: auto;
				}
			}

			&--copyright {
				grid-column: 4 / 7;
				grid-row: 2;
			}
		}

		&--copyright {
			display: flex;
			align-items: flex-end;
			justify-content: flex-end;
		}
	}
}

@include from($bp-very-large) {
	.l-site-footer {
		@supports (display: grid) {
			grid-gap: $padding-rythm-lg / 2;
		}
	}
}
