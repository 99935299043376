.l-form {
	> .l-stripe-card,
	> .form-choice-buttons,
	> .form-row,
	> .form-field {
		+ .form-field,
		+ .form-row,
		+ .btn-group,
		+ .form-choice-buttons,
		+ .l-stripe-card,
		+ .btn {
			margin-top: $padding-rythm-sm;
		}
	}

	&--title {
		font-size: $fs-h3-sm;
		color: $red-7;
		margin-top: $padding-rythm-sm * 2;
	}
	&--group-title {
		font-weight: bold;
		margin-top: $padding-rythm-sm;
		margin-bottom: $padding-rythm-sm;
	}
}

@include from($bp-medium) {
	.l-form {
		&--title {
			font-size: $fs-h3;
			margin-top: $padding-rythm-sm * 4;
		}
		&--group-title {
			margin-top: $padding-rythm-sm * 4;
		}

		&--title + &--group-title {
			margin-top: $padding-rythm-sm * 2;
		}
	}
}
