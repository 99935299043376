.event {
	background: $grey-1;
	position: relative;
	font-weight: bold;
	color: $grey-3;

	&:after {
		transition: transform 180ms ease-in-out;
		content: "";
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		border: 2px solid $red-6;
		transform: translate(6px, 6px);
		z-index: -1;
	}

	&:hover,
	&:focus,
	&:active {
		&:after {
			transform: translate(10px, 10px);
		}

		.event--heading:after {
			transform: translateX(4px);
		}
	}

	&--details {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		flex: 1;
		padding: $padding-rythm-sm;
	}

	&--heading {
		display: flex;
		justify-content: space-between;
		position: relative;
		padding-right: $padding-rythm-sm * 1.5;

		&__date {
			text-transform: uppercase;
		}

		&:after {
			transition: transform 180ms ease-in-out;
			content: ">>";
			display: inline-block;
			position: absolute;
			top: 50%;
			width: 18px;
			height: 16px;
			right: 0;
			margin-top: -8px;
			margin-left: -9px;
		}
	}

	&--title {
		flex: 1;
		font-size: $fs-h3-sm;
		line-height: 1.4;
		margin-top: 10px;
		min-height: 84px;
		color: $black;
	}
}

@include from($bp-large) {
	.event {
		display: flex;

		&--image {
			width: 30.5%;
		}

		&--details {
			padding-right: $padding-rythm-sm * 1.5;
		}

		&--title {
			min-height: initial;
		}
	}
}
