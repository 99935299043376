.browser-detect {
	padding: $padding-rythm-md $padding-rythm-sm;
	position: relative;
	z-index: 1000;
	h2 {
		margin-top: 0;
	}
	&--button {
		display: inline-block;
		margin-top: 1em;
		color: $red-7;
		border-bottom: 2px solid $red-7;
		width: auto;
	}
}

@include from($bp-medium) {
	.browser-detect {
		padding: $padding-rythm-md;
	}
}

@include from($bp-large) {
	.browser-detect {
		padding: $section-padding;
	}
}
