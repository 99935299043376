@mixin block-base-sm() {
	background: white;
	padding: $padding-rythm-sm;
}

@mixin block-base() {
	background: white;
	padding: $padding-rythm-md;
}

@mixin base-heading() {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	line-height: 1;
	font-family: $heading-font;
	margin: 0;
}

@mixin base-colored-title() {
	display: inline;
	line-height: 1.5;
	box-decoration-break: clone;
	padding: 0 $title-padding;
}

@mixin base-link() {
	transition: text-decoration-color 150ms ease-in;
	text-decoration-color: currentColor;
	text-decoration: underline;
	text-underline-offset: 0.4em;
	color: $red-9;

	&:hover,
	&:focus,
	&:active {
		text-decoration-color: $off-white-2;
	}
}

@mixin base-buildings-container($overwritePosition: true) {
	&--building {
		position: absolute;
		z-index: 2;

		top: 38vw;
		right: -40px;

		opacity: 0;
		will-change: transform;
		pointer-events: none;
		&__first {
		}
		&__second {
			right: -80px;
			left: auto;
		}

		.buildings-mounted & {
			opacity: 1;
			bottom: auto;
		}

		&[data-building-layer="2"] {
			z-index: 2;
		}

		&__hide-mobile {
			display: none;
		}
	}

	@include from($bp-medium) {
		&--building {
			z-index: 1;

			top: auto;
			bottom: -105px;
			left: -80px;
			&__second {
				right: -80px;
				left: auto;
			}

			&__hide-mobile {
				display: block;
			}
		}
	}

	@if $overwritePosition {
		position: relative;
		.buildings-mounted & {
			position: static;
		}
	}
}
