.room-overview {
	text-align: center;
	&--floorplan {
		height: 200px;
		margin-bottom: $padding-rythm-sm * 2;
		svg {
			height: 100%;
			width: auto;
			margin: 0 auto;
		}
	}
	&--type {
		font-size: $fs-h3;
		color: $red-7;
		margin-bottom: 0.2em;
	}
	&--notice {
		font-size: $fs-body-sm;
		color: $red-7;
		letter-spacing: 1px;
	}
	&--price {
		color: $grey-5;
		&-figure {
			font-size: $fs-h2;
			font-weight: bold;
			-webkit-font-smoothing: auto;
			-moz-osx-font-smoothing: auto;
		}
	}
	&--frequency {
		color: $grey-5;
		letter-spacing: 1px;
		text-transform: uppercase;
	}
}

@include from($bp-medium) {
	.room-overview {
		&--floorplan {
			height: 215px;
		}
	}
}
