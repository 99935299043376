.captioned-media {
	&--asset {
		width: 100%;
		height: auto;
		display: block;
	}
	&--caption {
		color: $grey-4;
		font-size: $fs-body-md;
		margin-top: $padding-rythm-sm / 2;
	}
}
