.blockquote {
	margin: 0;
	font-style: italic;
	font-size: $fs-h3-sm;
	color: $red-9;
	line-height: 1.4;
	&--citation {
		font-size: $fs-body;
		margin-top: $padding-rythm-sm / 2;
	}
}
