.form-row {
}

@include from($bp-medium) {
	.form-row {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-gap: $form-grid-padding;
	}
}
