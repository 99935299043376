.l-page {
	&--inner {
		padding: $padding-rythm-sm;
		background: white;
	}
}

@include to($bp-medium) {
	.l-page {
		&--inner {
			padding-bottom: $padding-rythm-lg;
		}
		&--sidebar + &--body {
			margin-top: $padding-rythm-md;
		}
		&--building {
			display: none;
		}
	}
}

@include from($bp-medium) {
	.l-page {
		@include base-buildings-container;

		&--building {
			&__first {
				left: calc(((100vw - #{$max-content-width}) / 2) - 120px);
			}
			&__second {
				right: calc(((100vw - #{$max-content-width}) / 2) - 120px);
			}
		}

		&--inner {
			position: relative;
			z-index: 2;
			padding: $padding-rythm-sm * 2;
			max-width: $max-content-width;
			margin: 0 auto;
			margin-top: -$padding-rythm-sm;
			margin-bottom: $padding-rythm-lg + $padding-rythm-md;
		}

		@supports (display: grid) {
			&--inner {
				display: grid;
				grid-gap: $padding-rythm-sm * 2;
				grid-template-columns: $content-sidebar-size 1fr;
			}

			&--body {
				&__full {
					grid-column: span 2;
				}
			}
		}
	}
}
