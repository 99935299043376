*,
*:before,
*:after {
	box-sizing: border-box;
}

body,
html {
	padding: 0;
	margin: 0;
}

ul,
ol {
	margin: 0;
	padding: 0;
	display: block;
}

ul li {
	list-style: none;
}

img {
	width: 100%;
	height: auto;
	display: block;
}

p {
	margin: 0;
}

body {
	position: relative;
}

// Generally speaking button and anchor elements are interchangable style wise so make them
// consistend from the start. -JS
a,
button {
	color: inherit;
	display: block;
	text-decoration: none;
	border: 0 none;
	appearance: none;
	background: none;
	padding: 0;
	line-height: 1;
	display: block;
	width: 100%;
	text-align: left;
}
