$control-size: 20px;

.form-choice-buttons {
	display: grid;
	grid-gap: $form-grid-padding;
	grid-template-columns: repeat(2, 1fr);

	&--input {
		grid-column: span 2;
		position: relative;

		&:before {
			content: "$";
			color: $red-4;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			left: $padding-rythm-sm / 2;
		}

		input {
			width: 100%;
			border: 0 none;
			padding: 8px $padding-rythm-sm / 2;
			padding-left: $padding-rythm-sm * 1.2;
			width: 100%;
			line-height: 1.4;
			appearance: none;
			border-radius: 0;
			border: 2px solid $red-4;
			color: $black;
			&::placeholder {
				color: $grey-2;
			}
		}
	}

	// TODO: Could probably make some base mixins in bases and use them here and in form-field -JS
	&--boolean {
		$b-root: &;

		display: block;
		input {
			@include visuallyhidden;
			&:focus + #{$b-root}-label {
				background: $red-5;
			}
			&:checked + #{$b-root}-label {
				background: $red-7;
				&:after {
					transform: scale(0.6);
				}
			}
			&:checked:focus + #{$b-root}-label {
				&:after {
					transform: scale(0.3);
				}
			}
		}
		&-label {
			transition: background 200ms ease-in-out;
			position: relative;
			background: $red-3;
			color: white;
			padding: 9px $padding-rythm-sm / 2;
			padding-right: $padding-rythm-sm * 2;
			display: block;

			&:before,
			&:after {
				content: "";
				width: $control-size;
				height: $control-size;
				position: absolute;
				right: $padding-rythm-sm / 2;
				top: 50%;
				margin-top: -#{$control-size/2};
				color: white;
				border-radius: 100%;
			}
			&:before {
				border: 2px solid;
			}
			&:after {
				transition: transform 200ms ease-in-out;
				background: white;
				transform: scale(0);
			}
			&:hover {
				cursor: pointer;
				background: $red-5;
				&:after {
					transform: scale(0.3);
				}
			}
		}
	}
}

@include from($bp-medium) {
	.form-choice-buttons {
		grid-template-columns: repeat(5, 1fr);
		&--input {
			grid-column: span 3;
		}
		&--boolean {
			&__other {
				grid-column: span 2;
			}
		}
	}
}
