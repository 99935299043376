.card {
	display: flex;
	flex-direction: column;
	justify-content: center;
	color: white;
	position: relative;

	&:after {
		transition: transform 180ms ease-in-out;
		content: "";
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		border: 2px solid $black;
		transform: translate(6px, 6px);
	}

	&--title,
	&--image {
		position: relative;
		z-index: 1;
	}

	&--title {
		padding: ($padding-rythm-sm * 0.5) + 2px $padding-rythm-sm * 0.5;
		padding-right: $padding-rythm-sm * 2 + 5px;
		background: $grey-5;
		flex: 1;
		flex-basis: 100%;

		display: flex;
		align-items: center;

		&:after {
			transition: transform 180ms ease-in-out;
			content: ">>";
			display: inline-block;
			position: absolute;
			top: 50%;
			width: 18px;
			height: 16px;
			right: $padding-rythm-sm;
			margin-top: -8px;
			margin-left: -9px;
		}
	}

	&--image {
		flex: 1 1 auto; // IE11 fix
		height: 100%; // IE11 fix
	}

	&:hover,
	&:focus,
	&:active {
		&:after {
			transform: translate(10px, 10px);
		}
		.card {
			&--title {
				&:after {
					transform: translateX(4px);
				}
			}
		}
	}

	&__t-default {
	}
	&__t-red {
		&:after {
			border-color: $red-7;
		}
		.card {
			&--title {
				background: $red-5;
			}
		}
	}
}

@include to($bp-medium) {
	.card-group {
		.card + .card {
			margin-top: $padding-rythm-sm;
		}

		&__minimal {
			.card--image {
				display: none;
			}
		}
	}
}

@include from($bp-medium) {
	.card {
		&--title {
			padding: $padding-rythm-md / 2;
			padding-right: $padding-rythm-md;
		}
	}

	.card-group {
		display: flex;

		.card {
			margin-left: $padding-rythm-sm;
			&:first-child {
				margin-left: 0;
			}
		}

		@supports (display: grid) {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			grid-gap: $padding-rythm-sm;

			.card {
				margin-left: 0;
			}
		}
	}
}
