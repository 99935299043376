$control-size: 20px;

.form-boolean {
	$c-root: &;

	input {
		@include visuallyhidden;
		&:focus + #{$c-root}--label {
			text-decoration: underline;
		}
	}
	&--label {
		position: relative;
		padding-left: 1.5 * $control-size;
		color: $grey-2;
		&:before,
		&:after {
			content: "";
			width: $control-size;
			height: $control-size;
			position: absolute;
			left: 0;
			top: 50%;
			margin-top: -#{$control-size/2};
			color: $red-4;
		}
		&:before {
			border: 2px solid;
		}
		&:after {
			transition: transform 200ms ease-in-out;
			background: $red-4;
			transform: scale(0);
		}
		&:hover {
			cursor: pointer;
			&:after {
				transform: scale(0.3);
			}
		}
		&-required {
			color: red;
		}
	}
	input[type="radio"] + &--label {
		&:before,
		&:after {
			border-radius: 100%;
		}
	}
	input:checked + &--label {
		&:after {
			transform: scale(0.6);
		}
	}
}

.form-boolean-group {
	&--label {
		display: block;
		margin-bottom: $form-grid-padding-v;
	}
	.form-boolean {
		+ .form-boolean {
			margin-top: $padding-rythm-sm;
		}
	}
}
