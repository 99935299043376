.social-bar {
	$c-root: &;

	&--label {
		margin-bottom: $padding-rythm-sm;
		font-weight: bold;
	}
	&--list {
		display: flex;
		list-style: none;
		padding: 0;
		margin: 0;
	}
	&--item {
		display: inline-block;
		margin-right: $padding-rythm-sm / 2;
	}
	&--link {
		display: block;
		width: 32px;
		height: 32px;
		color: white;
		padding: 8px;
		position: relative;

		&:before {
			content: "";
			position: absolute;
			z-index: 2;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			background: $grey-4;
		}

		&:after {
			transition: transform 180ms ease-in-out;
			content: "";
			position: absolute;
			width: 100%;
			height: 100%;
			z-index: 1;
			top: 0;
			left: 0;
			border: 2px solid $black;
			transform: translate(6px, 6px);
		}

		svg {
			z-index: 3;
			position: relative;
			width: 100%;
			height: auto;
			display: block;
			color: white;

			// Fixes very weird bug in Chrome 74.0.3729.131 where some svgs disappear if nested inside
			// element with CSS columns applied (even when not a direct child!!) -JS
			transform: translateZ(0);
		}

		&:hover,
		&:focus,
		&:active {
			&:after {
				transform: translate(10px, 10px);
			}
		}
	}

	&__in-page-content {
		#{$c-root}--label {
			font-size: $fs-h3-sm;
			color: $red-7;
			margin-bottom: 0;
		}
		#{$c-root}--list {
			margin-top: 1em;
		}
	}
}

@include from($bp-medium) {
	.social-bar {
		$c-root: &;

		&--label {
			margin-bottom: 48px; // To align with op of menu group list
		}

		&__in-page-content {
			#{$c-root}--label {
				font-size: $fs-h3;
			}
		}
	}
}
