.profile {
	background: $grey-6;
	color: white;
	&--copy {
		padding: $padding-rythm-sm;
		display: flex;
		flex-direction: column;

		&-cta,
		&-postnominal,
		&-description {
			font-size: $fs-body-md;
		}
		&-title,
		&-subtitle {
			font-weight: bold;
			line-height: 1.5;
		}
		&-title {
			margin-bottom: $padding-rythm-sm / 2;
		}
		&-postnominal {
			margin-bottom: $padding-rythm-sm / 2;
		}
		&-subtitle {
			margin-bottom: $padding-rythm-sm;
			color: $red-2;
			&:last-child {
				margin-bottom: 0;
			}
		}
		&-description {
			margin: $padding-rythm-sm 0;
		}
		&-cta {
			margin-top: auto;
			text-decoration: underline;
		}
		&-cta + &-cta {
			margin-top: $padding-rythm-sm / 2;
		}
	}
	&--image {
		img {
			height: 100%;
			object-fit: cover;
		}
	}
}

.profile-group {
	.profile + .profile {
		margin-top: $padding-rythm-sm;
	}
}

@include from($bp-small) {
	.profile {
		display: flex;
		&--copy {
			max-width: 100%;
			padding: $padding-rythm-sm * 2;
			flex: 1;
		}
		&--image {
			max-width: 100%;
			flex: 1;
		}
	}

	.profile-group {
		.profile + .profile {
			margin-top: $padding-rythm-sm;
		}
		&__grid {
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-gap: $padding-rythm-sm;
			.profile + .profile {
				margin-top: 0;
			}
		}
	}
}

@include from($bp-medium) {
	.profile-group {
		.profile + .profile {
			margin-top: $padding-rythm-sm * 2;
		}
		&__grid {
			grid-gap: $padding-rythm-sm * 2;
			.profile + .profile {
				margin-top: 0;
			}
		}
	}
}

@include from($bp-large) {
	.profile {
		&--image {
			min-width: 330px;
		}
	}
}
