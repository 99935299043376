html {
	width: 100%;
	overflow-x: hidden;
}
body {
}

body {
	font-size: 16px;
	font-family: $body-font;
	line-height: 1.5;
	color: $black;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	&.preload {
		* {
			transition: none !important;
		}
	}
}

h1,
h2,
h3,
h4,
h5,
h6 {
	@include base-heading;
}

h1 {
	font-size: $fs-h1-sm;
}

h2 {
	font-size: $fs-h2-sm;
}

h3 {
}

h4 {
}

h5 {
}

.colorful-title {
	font-size: $fs-h2-sm;
	margin-bottom: $padding-rythm-md;
	&--inner {
		@include base-colored-title;
		color: $off-white-1;
		background: $red-7;

		// TODO: Themes
	}
}

.lede {
	font-size: 1.2em;
}

.responsive-object {
	position: relative;
	iframe,
	img {
		position: absolute;
		height: 100%;
		width: 100%;
	}
}

.richtext,
.rich-text {
	> * + * {
		margin-top: 1.5em;
	}
	> * + h1,
	> * + h2 {
		margin-top: 1em;
	}

	h2,
	h3 {
		line-height: 1.4;
	}

	h2 {
		font-size: $fs-h3-sm;
		margin-top: $padding-rythm-sm * 4;
		color: $red-9;
	}
	h3 {
		font-size: $fs-body;
		font-weight: bold;
		color: $red-9;
	}

	a:not([class]) {
		@include base-link;
		display: inline;
	}

	> a:not([class]) {
		display: inline-block;
		width: auto;
	}

	address {
		font-style: normal;
	}

	ol,
	ul {
		padding: 0;
		li {
			list-style-type: none;
			padding-left: $padding-rythm-md / 2;
			position: relative;

			&:before {
				position: absolute;
				left: 0;
			}

			+ li {
				margin-top: $padding-rythm-sm / 2;
			}
		}
	}

	ol {
		li {
			counter-increment: list;
			&:before {
				content: counter(list) ".";
				color: $red-3;
			}
		}
	}

	ul {
		li {
			&:before {
				content: "•";
				color: $red-6;
			}
		}
	}

	dl {
		dt,
		dd {
			margin: 0;
		}
		dt {
			@include base-heading;
			font-size: $fs-body;
			font-weight: bold;
			color: $red-7;
			margin-bottom: $padding-rythm-sm / 2;
		}
		dd {
			margin-bottom: $padding-rythm-sm;
			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	table {
		tr {
			border-bottom: 1px solid;
			&:last-child {
				border-bottom: 0 none;
			}
		}
		th,
		td {
			vertical-align: top;
			text-align: left;
		}
		th {
			font-weight: normal;
			padding-bottom: $padding-rythm-sm / 2;
			padding-right: $padding-rythm-sm;
		}
		td {
			padding-top: $padding-rythm-sm / 4;
			padding-bottom: $padding-rythm-sm / 2;
			padding-right: $padding-rythm-sm;
		}
	}

	&-image {
		padding-top: $padding-rythm-sm;
		position: relative;

		&.full-width {
			width: 100%;
			height: auto;
		}
	}
}

@include from($bp-medium) {
	h1 {
		font-size: $fs-h1;
	}
	h2 {
		font-size: $fs-h2;
	}

	.colorful-title {
		font-size: $fs-h2;
		margin-bottom: $padding-rythm-lg;
	}

	.richtext,
	.rich-text {
		h2 {
			font-size: $fs-h3;
			margin-top: $padding-rythm-sm * 4;
		}

		&:first-child {
			h2:first-child {
				margin-top: 0;
			}
		}

		&-image {
			&.left,
			&.right {
				max-width: 50%;
				display: block;
			}
			&.right {
				margin-left: auto;
			}
		}
	}
}
