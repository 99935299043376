.page-banner {
	position: relative;
	&--super-title,
	&--title {
		@include base-colored-title;
		font-size: $fs-body;
	}
	&--super-title {
		font-size: $fs-body;
		background: $off-white-1;
		color: $red-8;
		// TODO themes
	}
	&--title {
		font-size: $fs-h1-sm;
		color: $grey-1;
		background: $red-8;
		// TODO themes
	}

	&--copy {
	}
}

@include to($bp-medium) {
	.page-banner {
		display: flex;
		flex-direction: column-reverse;

		&--copy {
			margin-top: -#{$fs-h1-sm + $fs-body};
			position: relative;
			z-index: 1;
			padding: 0 $padding-rythm-sm;
		}
	}
}

@include from($bp-medium) {
	.page-banner {
		height: 0;
		overflow: hidden;
		padding-bottom: 650 / 1440 * 100%;
		&--image {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
		}
		&--copy {
			left: $section-padding;
			position: absolute;
			top: 50%;
			z-index: 1;
			transform: translateY(-50%);
		}
		&--title {
			font-size: $fs-h1;
		}
	}
}

@include from($max-site-width + $section-padding) {
	.page-banner {
		&--copy {
			left: calc((100vw - #{$max-site-width}) / 2);
		}
	}
}
