.l-marketing-section {
	$c-root: &;

	// Shared
	background: white;
	@include base-buildings-container;

	padding: $padding-rythm-sm * 5 $padding-rythm-sm;

	&--title {
		&-inner {
			@include base-colored-title;
			color: $red-8;
			background: $off-white-3;
		}
	}

	&__t-default + &__t-default {
		padding-top: 0;
	}

	&__home-fold {
		padding-top: 0;
		#{$c-root}--ctas {
			&-banner {
				&-cover {
					margin-top: 0;
				}
			}
		}
	}

	// Section type specifc
	&--ctas {
		&-banner {
			display: flex;
			flex-direction: column-reverse;
			&-copy {
				max-width: 100%;
				position: relative;
				z-index: 3;
				&-text {
					max-width: 100%;
				}
				#{$c-root}--title {
					margin-bottom: $padding-rythm-sm;
				}
				.btn {
					margin-top: $padding-rythm-sm * 2;
					display: inline-block;
					width: auto;
				}
			}
		}
	}
	&--proof {
		margin-top: $padding-rythm-sm * -6;
	}

	// Themes
	&__t-pink {
		background: $off-white-3;
		.l-marketing-section {
			&--title {
				&-inner {
					color: white;
					background: $grey-6;
				}
			}
		}
	}
	&__t-grey {
		background: $grey-5;
		.l-marketing-section {
			&--title {
				&-inner {
					color: $off-white-3;
					background: $red-9;
				}
			}
			&--ctas-banner-copy-text {
				color: white;
			}
		}
	}
	&__t-dark-grey {
		background: $grey-6;
		.l-marketing-section {
			&--title {
				&-inner {
					color: $off-white-1;
					background: $black;
				}
			}
			&--ctas-banner-copy-text {
				color: white;
			}
		}
	}
	&__t-pricing {
		background: $grey-6;
		.l-marketing-section {
			&--title {
				&-inner {
					color: white;
					background: $red-5;
				}
			}
			&--ctas-banner-copy-text {
				color: white;
			}
		}
	}
	&__t-red {
		background: $red-9;
		.l-marketing-section {
			&--title {
				&-inner {
					color: white;
					background: $grey-6;
				}
			}
			&--ctas-banner-copy-text {
				color: white;
			}
		}
	}
	&__t-red-title {
		background: white;
		.l-marketing-section {
			&--title {
				&-inner {
					color: $grey-1;
					background: $red-8;
				}
			}
		}
	}
	&__t-grey-title {
		background: white;
		.l-marketing-section {
			&--title {
				&-inner {
					color: $red-7;
					background: $grey-1;
				}
			}
		}
	}

	&__t-default + &__t-default {
		padding-top: 0;
	}
}

@include to(560px) {
	.l-marketing-section {
		&--title {
			br {
				display: none;
			}
		}
	}
}

@include to($bp-medium) {
	.l-marketing-section {
		$c-root: &;

		&--title {
			margin-top: -1em;
		}
		&--ctas {
			.card-group {
				display: none;
			}
			&-banner {
				&-cover {
					margin: 0 -#{$padding-rythm-sm};
				}
			}
		}

		&--building {
			&__second {
				right: auto;
				left: -80px;
				top: auto;
				bottom: 0;
			}
		}

		// Ensure these parts stay above the buildings
		.l-marketing-section--pricing,
		.l-marketing-section--proof,
		.l-marketing-section--stats,
		.l-marketing-section--ctas-banner-copy {
			position: relative;
			z-index: 3;
		}

		&--building__first {
			top: 5vw;
		}

		&__large {
			#{$c-root}--building__first {
				top: 30vw;
			}
		}

		&__has-banner {
			padding-top: 0;
		}

		&__has-proof-after {
			padding-bottom: $padding-rythm-sm * 6;
		}

		&__tight {
			padding: $padding-rythm-sm * 2 $padding-rythm-sm $padding-rythm-sm * 5 $padding-rythm-sm;
		}

		// Pull the map out slightly to make it as big as possible -JS
		&--map {
			margin: 0 -15px;
			z-index: 10;
			position: relative;
		}
		&--map &--title {
			margin-left: 15px;
			margin-right: 15px;
			margin-top: 0;
			margin-bottom: $padding-rythm-md;
		}

		&--pricing {
			#{$c-root}--title {
				margin-top: 0;
				margin-bottom: $padding-rythm-sm;
			}
			#{$c-root}--pricing-cta {
				margin-bottom: $padding-rythm-md;
			}
		}
	}
}

@include from($bp-medium) {
	.l-marketing-section {
		$c-root: &;

		// Shared
		padding: $padding-rythm-md;

		&--wrapper {
			max-width: $max-site-width;
			margin: 0 auto;
		}

		&--inner {
			position: relative;
			z-index: 2;
		}

		// Section type specifc
		&--ctas {
			margin-right: -$section-padding;
			&-banner {
				display: flex;
				flex-direction: row;
				&-cover {
					width: 69.5%;
					&-image {
						height: 100%;
						min-height: 34.7vw;
						overflow: hidden;
						background: white;
						position: relative;
						img {
							position: absolute;
							width: 100%;
							height: 100%;
							top: 0;
							left: 0;
							object-fit: cover;
							object-position: center center;
						}
					}
				}

				&-copy {
					width: 30.5%;
					display: flex;
					flex-direction: column;
					align-items: flex-start;
					padding-right: $padding-rythm-md;
					#{$c-root}--title {
						margin-top: $padding-rythm-sm * 2;
						margin-bottom: $padding-rythm-md;
						width: 70vw;
						margin-left: -$title-padding;
					}
					.btn {
						width: auto;
						margin-top: $padding-rythm-sm * 2;
					}
				}
			}

			.card-group {
				width: 69.5%;
				margin-left: auto;
				padding-right: $section-padding;
				padding-left: $padding-rythm-md / 2;

				// Overlap with banner above
				margin-top: -$padding-rythm-sm;
				position: relative;
				z-index: 1;
			}
		}
		&--proof,
		&--stats {
			margin-top: -($padding-rythm-md + $padding-rythm-sm);
		}
		&--pricing {
			position: relative;
			.l-pricing-block {
				margin-top: $section-padding;
			}
			&-cta {
				position: absolute;
				top: 0;
				right: 0;
			}
			.l-marketing-section--title {
				width: 50%;
			}
		}

		&__t-default + &__t-default {
			padding-top: 0;
		}

		&__home-fold {
			&:first-child {
				padding-top: 0;
			}
			#{$c-root}--title {
				margin-top: $padding-rythm-sm * 2 + $header-height-dtop;
			}
		}

		&__large {
			#{$c-root}--ctas-banner-cover-image {
				min-height: 45vw;
			}
			#{$c-root}--ctas-banner-copy {
				justify-content: center;
			}
		}
	}
}

@include from($bp-large) {
	.l-marketing-section {
		$c-root: &;

		// Shared
		padding: 140px $section-padding;
		&:first-child {
			padding-top: 0; // This may be ill advised long term... but works right now to remove gap for top of homepage and is simple. -JS
		}
		&__t-default + &__t-default {
			padding-top: 0;
		}

		&--title {
			font-size: $fs-h2;
		}

		&--proof,
		&--stats {
			margin-top: -(140px + ($padding-rythm-sm * 2));
		}

		&__large {
			#{$c-root}--title {
				font-size: $fs-h1;
			}
		}
	}
}

@include from($bp-very-large) {
	.l-marketing-section {
		$c-root: &;

		&--title {
			font-size: $fs-h2 * $v-lg-screen-fs-mod;
		}

		&__large {
			#{$c-root}--title {
				font-size: $fs-h1;
			}
		}

		&__home-fold {
			#{$c-root}--title {
				margin-top: $padding-rythm-sm * 2;
			}
		}
	}
}
