.btn {
	position: relative;
	font-weight: bold;

	&--inner {
		transition: padding-right 180ms ease-in-out;
		position: relative;
		z-index: 1;
		background: $grey-4;
		color: white;
		display: block;
		width: 100%;
		padding: ($padding-rythm-sm * 0.5) + 2px $padding-rythm-sm * 0.5;
		padding-right: $padding-rythm-sm * 2 + 5px;

		&:after {
			transition:
				transform 180ms ease-in-out,
				width 180ms ease-in-out;
			content: ">>";
			display: inline-block;
			position: absolute;
			top: 50%;
			width: 18px;
			height: 16px;
			right: $padding-rythm-sm * 0.5;
			margin-top: -7px;
			margin-left: -9px;
		}
	}

	&:after {
		transition: transform 180ms ease-in-out;
		content: "";
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		border: 2px solid $black;
		transform: translate(6px, 6px);
	}

	&:hover,
	&:focus,
	&:active {
		&:after {
			transform: translate(10px, 10px);
		}
		.btn {
			&--inner {
				&:after {
					// transform: translateX(4px);
				}
			}
		}
	}

	&__t-red {
		.btn--inner {
			background: $red-9;
		}
		&:after {
			border-color: $red-7;
		}
	}
}

.btn-group {
	.btn {
		display: inline-block;
		width: auto;
	}
	.btn + .btn {
		margin-left: $padding-rythm-sm;
	}
}
