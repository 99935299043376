/**
 * Media Queries - *Try* and make everything fit within these - use @from/to/between
 */
$bp-small: 768px;
$bp-medium: 1024px;
$bp-large: 1280px;
$bp-very-large: 1441px;

/**
 * Font definitions
 */
$defaultFontSize: 1;

$body-font: "poppins", sans-serif;
$heading-font: "poppins", sans-serif;

/**
 * Font Sizes
 */
$fs-h1: 60px;
$fs-h2: 40px;
$fs-h3: 24px;

$fs-h1-sm: 40px;
$fs-h2-sm: 28px;
$fs-h3-sm: 20px;

$fs-body: 16px;
$fs-body-md: 14px;
$fs-body-sm: 12px;

/**
 * Transitions
 */
$base-anim-rythm: 300ms;
$transition-default: all $base-anim-rythm ease-in-out;

/**
 * Padding
 * Usage: padding: $padding-rythm*2
 */
$padding-rythm-lg: 100px;
$padding-rythm-md: 60px;
$padding-rythm-sm: 20px;

/**
 * Raw Colors
 */
$black: #130c0e;

$grey-1: #e8e6e6;
$grey-2: #7f7175;
$grey-3: #665b5e;
$grey-4: #4c4446;
$grey-5: #332d2f;
$grey-6: #191617;

$red-9: #cc1b28;
$red-8: #b21823;
$red-7: #ed1c24;
$red-6: #e83643;
$red-5: #ea424f;
$red-4: #ea5762;
$red-3: #ea717a;
$red-2: #e88b92;
$red-1: #e5a8ac;

$off-white-1: #ffe6ee;
$off-white-2: #e5cacc;
$off-white-3: #e5ccd3;
$off-white-4: #b29ea4;

/**
 * z-index stack - where possible use DOM order or small hoists (eg z-index: 1 vs z-index: $zi-layer-4)
 */
$zi-layer-1: 20;
$zi-layer-2: 40;
$zi-layer-3: 60;
$zi-layer-4: 80;
$zi-layer-nav: 200;

$form-grid-padding-v: 12px;
$form-grid-padding: $form-grid-padding-v $padding-rythm-sm;

/**
 * Misc
 */
$max-content-width: 1120px;
$max-site-width: 1920px;
$title-padding: 10px;
$section-padding: 80px;
$header-height-dtop: 100px;
$content-sidebar-size: 300px;
$v-lg-screen-fs-mod: 1.3;
