$choices-selector: "choices";
$choices-font-size-lg: $fs-body;
$choices-font-size-md: $fs-body;
$choices-font-size-sm: $fs-body;
$choices-guttering: $padding-rythm-sm / 2;
$choices-border-radius: 2px;
$choices-border-radius-item: 20px;
$choices-bg-color: white;
$choices-bg-color-disabled: #999999;
$choices-bg-color-dropdown: #ffffff;
$choices-text-color: $black;
$choices-keyline-color: $red-9;
$choices-primary-color: $red-9;
$choices-disabled-color: #999999;
$choices-highlight-color: $choices-primary-color;
$choices-button-dimension: 8px;
$choices-button-offset: 8px;

// @import "~choices.js/assets/styles/scss/choices";
@import "../vendor/choices";

.form-field {
	position: relative;
	width: 100%;

	&--field,
	&--label {
		display: block;
		line-height: 1.3;
		background: none;
	}

	.choices__inner,
	&--field {
		border: 0 none;
		padding: 8px $padding-rythm-sm / 2;
		width: 100%;
		line-height: 1.4;
		appearance: none;
		border-radius: 0;
		border: 2px solid $red-9;
		color: $black;
		&::placeholder {
			color: $grey-2;
		}

		&:active,
		&:focus {
			outline: 4px solid $red-9 !important;
		}
	}

	&--boolean-group {
		> * + * {
			margin-top: $padding-rythm-sm / 2;
		}
	}

	&--label {
		margin-bottom: $form-grid-padding-v;
		color: $red-9;
	}

	&--error-messages {
		color: red;
		width: 100%;
		&-single {
			margin-top: $padding-rythm-sm / 2;
		}
	}

	&--help {
		width: 100%;
		padding: $padding-rythm-sm / 2;
	}
}
