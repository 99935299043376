.l-stripe-card {
	.form-field {
		margin-top: 0;
	}

	&--elements {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-gap: $padding-rythm-sm;
		.form-field__card-number {
			grid-column: span 2;
		}
	}
}
