.l-text-columns {
	&--group {
		&.rich-text {
			h2 {
				&:first-child {
					margin-top: 0;
				}
			}
		}
	}
	&--group + &--group {
		margin-top: $padding-rythm-md;
	}
}

@include from($bp-medium) {
	.l-text-columns {
		columns: 3;
		column-gap: $section-padding;
		&--group {
			-webkit-column-break-inside: avoid;
			page-break-inside: avoid;
			break-inside: avoid-column;
		}
	}
}
