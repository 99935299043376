.page-navigation {
	&--list {
		background: $off-white-1;
		padding: $padding-rythm-sm / 2;
		color: $red-8;
	}

	&--item + &--item {
		margin-top: 2px;
	}
	&--link {
		padding: $padding-rythm-sm / 2;
		padding-right: $padding-rythm-sm * 2;
		position: relative;

		&.is-active {
			text-decoration: underline;
		}

		&:before {
			transition: opacity 180ms ease-in-out;
			content: "";
			position: absolute;
			width: calc(100% + #{$padding-rythm-sm});
			height: 100%;
			top: 0;
			left: -$padding-rythm-sm;
			border: 2px solid $red-8;
			opacity: 0;
		}

		&:after {
			transition: opacity 180ms ease-in-out;
			content: ">>";
			position: absolute;
			right: $padding-rythm-sm;
			top: 50%;
			transform: translateY(-42%); // Adjusted from -50% to deal with line-height
			opacity: 0;
		}

		&:focus,
		&:hover {
			&:after,
			&:before {
				opacity: 1;
			}
		}
	}

	&--sub-nav {
		background: $red-9;
		color: white;
		margin-right: -$padding-rythm-sm;
		margin-left: $padding-rythm-sm / 2;
		margin-top: $padding-rythm-sm / 2;
		margin-bottom: $padding-rythm-sm / 2;
		padding: $padding-rythm-sm / 2;
	}

	&--sub-nav &--link {
		&:before {
			border: 2px solid white;
		}
	}
}
