.social-proof {
	$c-root: &;

	background: $grey-6;
	color: $off-white-2;
	&--image {
	}
	&--quote {
		padding: $padding-rythm-sm;
		&-text {
			position: relative;
			padding: 0;
			margin: 0;
			font-size: $fs-h3-sm;
			font-style: italic;
			font-weight: bold;
			&:before,
			&:after {
				display: inline;
			}
			&:before {
				content: "“";
				position: absolute;
				left: -0.45em;
				top: 0;
			}
			&:after {
				content: "”";
				margin-left: -0.2em;
			}
		}
		&-attribution {
			color: white;
			margin-top: $padding-rythm-sm;
			font-weight: bold;
			&-degree {
				font-style: italic;
				font-weight: normal;
			}
		}
	}

	&--cta {
		width: max-content;
		max-width: 100%;
		margin-top: $padding-rythm-sm * 2;
	}

	&__t-default {
	}

	&__t-red {
		background: $red-7;
		color: $off-white-1;
	}
	&__t-light-pink {
		background: $red-1;
		color: $grey-5;
		#{$c-root}--quote {
			&-attribution {
				color: $grey-5;
			}
		}
	}
	&__t-pink {
		background: $red-9;
		color: white;
	}
}

@include from($bp-medium) {
	.social-proof {
		display: flex;
		&--quote {
			padding: 31px $padding-rythm-sm * 2; // Adjusted for line-height
			&-text {
			}
			&-attribution {
				margin-top: 30px; // Adjusted for line-height
				&-degree {
					margin-top: $padding-rythm-sm / 2;
				}
			}
		}

		&--quote,
		&--asset {
			flex: 1;
		}

		&--image {
			height: 100%;
			object-fit: cover;
		}

		@supports (display: grid) {
			display: grid;
			grid-template-columns: 7fr 9fr;
		}
	}
}

@include from($bp-large) {
	.social-proof {
		min-height: 355px;
		&--quote {
			&-text {
				font-size: $fs-h3;
			}
		}
	}
}

@include from($bp-very-large) {
	.social-proof {
		&--quote {
			&-text {
				font-size: $fs-h2-sm;
			}
		}
	}
}

@include from(1700px) {
	.social-proof {
		&--quote {
			&-text {
				line-height: 1.4;
				font-size: $fs-h2-sm * $v-lg-screen-fs-mod;
			}
		}
	}
}
