$mobile-header-height: 80px;

body {
	&.menu-open {
		height: 100.1vh; // Ensures that the scrollbar still stays visible so doesnt cause menu items to shift -JS
		overflow-y: hidden;
	}
}

.l-site-header {
	padding: $padding-rythm-sm;
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: relative;
	z-index: 100;

	&:before {
		content: "";
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		background: white;
		z-index: 2;
		pointer-events: none;
	}

	@include base-buildings-container($overwritePosition: false);

	&--building {
		left: 17px;
		top: -108px;

		svg {
			transform: rotate(-95deg);
		}

		.line {
			stroke: $red-7;
		}
		.block {
			fill: white;
		}

		&__home {
			top: -11.5vw;
			left: 50%;
			svg {
				transform: rotate(-19deg);
			}
		}
	}

	&--logo {
		width: 35%;
		max-width: 133px;
		&-img {
			width: 100%;
			height: auto;
		}
	}

	&--menu-trigger {
		width: 42px;
		height: 40px;
		z-index: 4;

		display: flex;
		align-items: center;
		justify-content: center;
	}

	&--logo,
	&--navigation {
		position: relative;
		z-index: 3;
	}

	&--navigation {
		display: none;
	}

	&--mega-navigation {
		padding: $padding-rythm-sm * 2;
		padding-top: $mobile-header-height + $padding-rythm-sm;

		transition: opacity 200ms ease-in-out;
		width: 100vw;
		height: 100vh;
		background: white;
		position: fixed;
		top: 0;
		left: 0;
		opacity: 0;
		z-index: 1;
		overflow-y: scroll;
		-webkit-overflow-scrolling: touch;
		pointer-events: none;

		&-inner {
			margin-bottom: $padding-rythm-lg;
		}

		&.is-active {
			opacity: 1;
			pointer-events: all;
			overscroll-behavior: contain;
		}
	}

	.browser-is-outdated & {
		position: relative;
	}
}

@include to($bp-medium) {
	.l-site-header {
		&--mega-navigation {
			&-inner {
				position: relative;
				padding-top: 58px;
				.btn__t-red {
					position: absolute;
					top: 0;
					left: 0;
				}
			}

			.menu-group {
				margin-top: $padding-rythm-sm;
				&:first-child,
				&:last-child {
					margin-top: 0;
				}
			}

			.menu-group--list,
			.menu-group--copy {
				display: none;
			}
			.social-bar {
				margin-top: $padding-rythm-sm * 2;
			}
			.btn {
				font-size: $fs-body-md;
				margin-bottom: 0;
			}
		}

		&--building {
			display: none;
		}
	}
}

@include from($bp-medium) {
	.l-site-header {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;

		// justify-content: flex-end;
		padding: $padding-rythm-md/2 $section-padding;

		&:before {
			transition: opacity 10ms ease-in-out;
			opacity: 0;
			.menu-open & {
				transition-delay: 200ms;
				opacity: 1;
				pointer-events: none;
			}
		}

		&--logo {
			width: 133px;
			margin-right: auto;
		}

		&--cta,
		&--navigation-item,
		&--building {
			transition: opacity 300ms ease-in-out;
			.menu-open & {
				opacity: 0;
				pointer-events: none;
			}
		}

		&--cta {
			margin-left: auto;
			margin-right: $padding-rythm-sm * 2;
			width: auto;
		}

		&--navigation {
			display: block;
			background: white;
			display: flex;
			padding: 12px;

			&.has-active-subelements .l-site-header--navigation-underline,
			&:hover .l-site-header--navigation-underline {
				opacity: 1;
			}

			&-underline {
				transition: opacity 300ms ease-in-out;
				content: "";
				width: 50px;
				height: 2px;
				background: $black;
				position: absolute;
				bottom: 6px;
				left: 0;
				opacity: 0;
				transform: translateZ(0);

				&.is-transitionable {
					transition:
						opacity 300ms ease-in-out,
						width 200ms ease-in-out,
						transform 200ms ease-in-out;
				}
			}

			&-item {
				white-space: nowrap;
				font-weight: bold;
			}
			&-item + &-item {
				margin-left: $padding-rythm-sm;
			}
		}

		&--menu-trigger {
			position: relative;
			top: auto;
			right: auto;
			background: white;
			padding-right: 2px;
		}

		&--mega-navigation {
			padding: $section-padding;
			padding-top: $header-height-dtop * 1.5;
			padding-right: $section-padding + $padding-rythm-lg;

			&-inner {
				width: 100%;
				@include flex-grid-container;
				align-content: flex-start;
				.menu-group {
					@include flex-grid-item(4, $section-padding);
				}
			}
		}

		&--building {
			&__home {
				left: 200px;
				svg {
					transform: rotate(-19deg);
				}
			}
		}
	}
}
