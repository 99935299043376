.l-streamfield {
	> * + * {
		margin-top: $padding-rythm-md;
	}

	> .rich-text + .rich-text {
		margin-top: 1.5em;
	}

	> .blockquote + *,
	> * + .blockquote {
		margin-top: $padding-rythm-sm;
	}

	.l-marketing-section__t-pricing {
		margin-left: -$padding-rythm-sm;
		margin-right: -$padding-rythm-sm;
	}
}

$page-inner-padding: $padding-rythm-sm * 4;
$page-remainder: unquote("((100vw - #{$max-content-width}) / 2)");
$page-offset: unquote("#{$page-remainder} + #{$page-inner-padding}");

@include from($bp-medium) {
	.l-streamfield {
		> .blockquote + *,
		> * + .blockquote {
			margin-top: $padding-rythm-sm;
		}

		.l-marketing-section__t-pricing {
			margin-left: -($page-inner-padding + $content-sidebar-size);
			margin-right: -$page-inner-padding;
		}
	}
}

@include from($max-content-width) {
	.l-streamfield {
		.l-marketing-section__t-pricing {
			margin-left: calc((#{$page-offset} + #{$content-sidebar-size}) * -1);
			margin-right: calc((#{$page-offset}) * -1);
		}
	}
}
