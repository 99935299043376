.l-pricing-block {
	&--column {
		&-header {
			margin-bottom: $padding-rythm-sm * 2;
		}
		&-toggle {
			color: $red-7;
			position: relative;
			padding-right: 10px;
			&:after {
				content: "";
				position: absolute;
				width: 10px;
				height: 10px;
				right: 0;
				top: 50%;
				border-right: 2px solid $red-7;
				border-top: 2px solid $red-7;
				margin-top: -5px;
				transform: rotate(135deg);
			}
			&.is-active {
				&:after {
					transform: rotate(-45deg);
				}
			}
			&:focus,
			&:hover,
			&:active {
				outline: none;
				text-decoration: underline;
			}
		}
		&-apply-cta {
			margin-top: $padding-rythm-sm * 2;
		}
		&-details {
			&-item {
				padding: $padding-rythm-sm / 2 0;
				border-bottom: 1px solid $off-white-4;
				&:first-child {
					padding-top: 0;
				}
				&:last-child {
					border-bottom: 0 none;
					padding-bottom: 0;
				}
			}
		}
		&__inclusions {
			background: white;
			padding: $padding-rythm-sm * 2 $padding-rythm-sm;
		}
	}
}

@include to($bp-small) {
	.l-pricing-block {
		&--column__inclusions + &--column__inclusions {
			margin-top: $padding-rythm-sm;
		}
	}
}

@include to($bp-medium) {
	.l-pricing-block {
		&--column {
			&-details {
				margin-top: $padding-rythm-sm;
				&-item {
					position: relative;
					padding-left: $padding-rythm-sm * 1.5 + 2px;
					&-name {
						display: block;
					}
					svg {
						position: absolute;
						top: $padding-rythm-sm/2;
						left: 0;
						width: 22px;
						height: 22px;
					}
					&:first-child {
						svg {
							top: 0;
						}
					}
				}

				display: none;
				&.is-visible {
					display: block;
				}
			}
			&__features {
				display: none;
			}
		}
	}
}

@include from($bp-small) {
	.l-pricing-block {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-gap: 2px;
	}
}

@include from($bp-medium) {
	.l-pricing-block {
		grid-template-columns: repeat(4, 1fr);
		grid-gap: $padding-rythm-sm;
		&--column {
			$col-root: &;

			position: relative;

			&-toggle {
				display: none;
			}

			&-details {
				&-item {
					display: flex;
					align-items: center;
					justify-content: center;
					min-height: 50px;
					&-name {
						border: 0;
						clip: rect(0 0 0 0);
						height: 1px;
						margin: -1px;
						overflow: hidden;
						padding: 0 !important;
						position: absolute !important;
						width: 1px;
					}
					svg {
						margin: 0 auto;
						width: 38px;
						height: 38px;
					}
				}
			}

			&-apply-cta {
				transition: 200ms opacity ease-in-out;

				position: absolute;
				bottom: 100%;
				left: 50%;
				transform: translateX(-50%);
				display: inline-block;
				width: auto;
				opacity: 0;
				pointer-events: none;
				margin-top: $padding-rythm-sm;
				.btn--inner {
					white-space: nowrap;
				}
			}

			&__inclusions {
				padding: $padding-rythm-sm;
				&:after {
					content: "";
					width: 100%;
					height: 100%;
					top: 0;
					left: 0;
					position: absolute;
					background: white;
					z-index: -1;
					transition: 180ms transform ease-in-out;
					transform: translateZ(0);
				}
				&:hover {
					#{$col-root}-apply-cta {
						transition-delay: 80ms;
						opacity: 1;
						pointer-events: all;
					}
					&:after {
						transform: scale(1.05) translateZ(0);
					}
				}
			}

			&__features {
				color: $off-white-3;
				padding: $padding-rythm-sm;
				padding-left: 0;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				#{$col-root}-details {
					&-item {
						justify-content: flex-start;
						border-bottom-color: $grey-4;
					}
				}
			}
		}
	}
}

@include from($bp-large) {
	.l-pricing-block {
		&--column {
			&__inclusions {
				padding: $padding-rythm-sm * 2;
			}
			&__features {
				padding: $padding-rythm-sm * 2;
				padding-left: 0;
			}
		}
	}
}
