.menu-group {
	.btn {
		width: auto;
		display: inline-block;
		margin-bottom: $padding-rythm-sm * 1.5;
	}

	&--copy,
	&--list-item {
		+ .menu-group {
			&--copy,
			&--list-item {
				margin-top: $padding-rythm-sm / 2;
			}
		}
	}

	&--copy,
	&--copy a,
	&--list-item-link {
		line-height: 1.5;
	}

	&--list {
		&-item {
			&-link {
				position: relative;
				padding-right: $padding-rythm-sm;
				&:after {
					transition:
						transform 180ms ease-in-out,
						opacity 180ms ease-in-out;
					content: ">>";
					position: absolute;
					top: 50%;
					width: 18px;
					height: 16px;
					right: 0;
					margin-top: -8px;
					margin-left: -9px;
					opacity: 0;
				}

				&:hover,
				&:focus,
				&:active {
					&:after {
						opacity: 1;
						transform: translateX(4px);
					}
				}
			}
		}
		&-item + &-item {
			margin-top: $padding-rythm-sm / 2;
		}
	}
}
